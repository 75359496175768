import React, { Component } from 'react'
import { graphql } from 'gatsby'

import styled from '@emotion/styled'
import { css } from '@emotion/core'

import { Section, Hero, Container, TitleCaption, SEO } from '../webhart-base'
import { colors, rhythm, transition } from '../webhart-base/utils/style'

import Layout from '../components/Layout'

import WorkList from '../components/WorkList'
import simpleIcon from '../../static/images/icons/simple.svg'
import advancedIcon from '../../static/images/icons/advanced.svg'
import customIcon from '../../static/images/icons/custom.svg'
import ScrollDown from '../components/ScrollDown'

const Tabs = styled.div`
  justify-content: center;
  overflow: hidden;
  padding-top: ${rhythm(1 / 2)};
  margin: 0 -${rhythm(1 / 2)};
  position: relative;
  display: flex;
`

const Tab = styled.button`
  flex: 0 1 250px;
  transition: ${transition};
  display: block;
  margin: ${rhythm(1 / 4)};
  padding: ${rhythm(1 / 2)};
  margin-bottom: -60px;
  padding-bottom: 65px;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
  img {
    max-height: 50px;
    margin: 0;
  }
  span {
    font-size: ${rhythm(2 / 3)};
    font-weight: 600;
    margin: 0;
    padding: 0;
    display: block;
  }
  &:focus {
    outline: 0;
  }
  &:hover {
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
  img {
    filter: grayscale(100%);
  }
  ${props =>
    props.active &&
    `
    box-shadow: 0 5px 30px rgba(0, 0, 0, 0.4);
    background: white;
    img{
      filter: none;
    }
  `};
`

const AllTabContent = styled.div`
  background: white;
  box-shadow: 0 ${rhythm(1)} 0 white, 0 0 30px rgba(0, 0, 0, 0.4);
  margin-bottom: ${rhythm(1)};
`

const TabContent = styled.div`
  padding-top: ${rhythm(2)};
  display: none;
  margin: 0 auto;
  text-align: left;
  & > h4,
  & > ul,
  & > p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }
  & > ul {
    max-width: 490px;
    padding-left: 20px;
  }
  h3 {
    &:after {
      content: '';
      width: 200px;
      margin: 10px auto;
      display: block;
      border-bottom: 1px solid rgba(0, 0, 0, 0.7);
    }
  }

  i {
    font-weight: 500;
    color: ${colors.orange};
  }
  strong {
    font-weight: 500;
    color: ${colors.blue};
  }
  ${props =>
    props.active &&
    `
    display: block;
  `};
`

const TabHeader = styled.div`
  text-align: center;
  p {
    color: ${colors.lightBlue};
  }
  h3 {
    margin-bottom: ${rhythm(1 / 2)};
  }
`

class WorkTogetherPage extends Component {
  constructor(props) {
    super(props)

    let hash = this.props.location.hash
    if (hash == '' || hash == undefined) {
      hash = 'mtm'
    } else {
      hash = hash.replace('#', '')
    }

    this.state = { hash: hash }
  }

  render() {
    const { data } = this.props

    let hash = this.props.location.hash
    if (hash == '' || hash == undefined) {
      hash = 'mtm'
    } else {
      hash = hash.replace('#', '')
    }

    if (this.state.hash != hash) {
      hash = this.state.hash
    }

    return (
      <Layout>
        <SEO
          title="pick your service"
          description="pick a website service based on your needs and budget"
        />
        <Hero
          size={50}
          color={colors.orange}
          image={data.HeroImage.childImageSharp.fluid}
          css={css`
            justify-content: flex-end;
            * {
              text-shadow: 1px 1px 0 ${colors.darkBlue};
            }
          `}
        >
          <h1>pick your service</h1>
          {/* <TitleCaption color="white">
            no better way to impress than with a tailor made suit
          </TitleCaption> */}
        </Hero>
        <Section>
          <Container width="wide">
            <h3>no better way to impress than with a tailor made suit</h3>
            <TitleCaption>
              In every project we keep the same clear goals in mind:{' '}
              <i>speed</i>, <i>security</i>, <i>SEO</i>, and <i>usability</i>{' '}
              across <i>all devices</i>.
            </TitleCaption>
            <Tabs>
              <Tab
                onClick={() => this.setState({ hash: 'mtm' })}
                active={hash == 'mtm'}
              >
                <img src={simpleIcon} alt="clothes hanger" />
                <span>simple</span>
              </Tab>
              <Tab
                onClick={() => this.setState({ hash: 'mto' })}
                active={hash == 'mto'}
              >
                <img src={advancedIcon} alt="scissors" />
                <span>advanced</span>
              </Tab>
              <Tab
                onClick={() => this.setState({ hash: 'bspk' })}
                active={hash == 'bspk'}
              >
                <img src={customIcon} alt="tape measure" />
                <span>custom</span>
              </Tab>
            </Tabs>
          </Container>

          <AllTabContent>
            <Container>
              <TabContent active={hash == 'mtm'}>
                <TabHeader>
                  <h3>Made to measure</h3>
                  <TitleCaption>
                    Cut and sewn using standard sized patterns
                  </TitleCaption>
                </TabHeader>
                <h4>includes:</h4>
                <ul>
                  <li>
                    basic pages built by combining standard page-sections (
                    <i>patterns</i>) as building blocks.
                  </li>
                  <li>
                    <i>blog</i> and post pages, blog <i>CMS</i>
                  </li>
                  <li>
                    standard contact <i>form</i>
                  </li>
                  <li>
                    website <i>analytics</i> for future site improvement
                  </li>
                </ul>
              </TabContent>
              <TabContent active={hash == 'mto'}>
                <TabHeader>
                  <h3>Made to order</h3>
                  <TitleCaption>
                    Created from standard patterns that are sized based on
                    specific requirements and measurements.
                  </TitleCaption>
                </TabHeader>
                <h4>
                  includes: <strong>made to measure</strong> +
                </h4>
                <ul>
                  <li>
                    <i>custom</i> post items and pages like: portfolio, gallery
                  </li>
                  <li>
                    with <i>CMS</i> to edit custom items
                  </li>
                  <li>
                    customized <i>page designs</i>
                  </li>
                </ul>
              </TabContent>
              <TabContent active={hash == 'bspk'}>
                <TabHeader>
                  <h3>Fully bespoke</h3>
                  <TitleCaption>
                    Patterns are created based on requirements and special
                    requests, fitted, measured and refitted until perfect
                  </TitleCaption>
                </TabHeader>
                <h4>
                  includes: <strong>made to measure</strong> +{' '}
                  <strong>made to order</strong> +
                </h4>
                <ul>
                  <li>
                    special <i>features</i> like: webshop, event registration,
                    with administration featueres
                  </li>

                  <li>
                    <i>unique</i> designs
                  </li>
                  <li>
                    <i>advanced</i> CMS for easy page-content updating
                  </li>
                </ul>
              </TabContent>
              <h4
                css={css`
                  &:after {
                    content: '';
                    width: 200px;
                    margin: 5px auto;
                    display: block;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.7);
                  }
                `}
              >
                example sites
              </h4>
              <WorkList items={data.portfolio.edges} hidePackage tag={hash} />
            </Container>
          </AllTabContent>
          <ScrollDown />
        </Section>
      </Layout>
    )
  }
}

export default WorkTogetherPage

export const WorkTogetherPageQuery = graphql`
  query WorkTogetherPageQuery {
    portfolio: allMarkdownRemark(
      filter: {
        frontmatter: {
          # draft: { ne: true }
          templateKey: { eq: "site" }
        }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      tags: group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      packages: group(field: frontmatter___package) {
        fieldValue
        totalCount
      }
      edges {
        node {
          ...WorkListFragment
        }
      }
    }
    HeroImage: file(base: { eq: "knoppen.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
